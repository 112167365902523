import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { tokdecn, tokdecn2, useAppContext } from "../App.js";
import { useQueries } from "react-query";
import {
  polytxnlink,
  q_activity,
  q_astokinfo,
  q_astokinfo_bidslist,
  q_astokinfo_list,
  q_astokinfo_owner,
  q_astokinfo_owner_cacref,
  q_astokinfo_txns,
  q_astoktxns_relayback,
  q_vaultinfo,
  qiserr,
  qissuccess,
  qissuccesss,
  q_watchlist_add,
  q_watchlist_del,
  q_astokinfo_report_falselist,
} from "../queries/queries.js";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nils,
  to_time_mini,
  toeth,
  tofeth,
  validate_eth_address,
} from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import {
  asset_types_map,
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import {
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
  ToolTip,
} from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { elementmap, gendermap, tablecn } from "../utils/cn_map.js";
import {
  faAnchor,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faDna,
  faF,
  faFlag,
  faHammer,
  faHeart,
  faLock,
  faMemory,
  faMessage,
  faRefresh,
  faSpinner,
  faTeletype,
  faTrash,
  faTriangleExclamation,
  faTrophy,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { InpDropdown, set_state_ob } from "../components/input.js";
import DNAMarket from "../contracts/DNAMarket/DNAMarket.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { contractAddress_list } from "../contracts/constants.js";
import { polychainimg } from "../utils/links";
import { Link } from "react-router-dom";
import { SkinCard } from "../components/ShortComps.js";
import { AssetInfoImg } from "./VaultPage.js";
import { ActivityListTable } from "./ActivityPage.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { polygon } from "thirdweb/chains";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { ethers } from "ethers";

const AssetContext = createContext();
export const useAssetContext = () => useContext(AssetContext);

export const get_dnamarket_conaddr = (token) => {
  return token == "WETH"
    ? contractAddress_list.market_weth
    : token == "DEZ"
      ? contractAddress_list.market_dez
      : null;
};

export const get_dnamarket_k = (token) =>
  token == "WETH" ? "market_weth" : token == "DEZ" ? "market_dez" : null;

const MiniStatCard = ({
  icon,
  icon_cn,
  title,
  card_cn,
  children,
  redirect = {},
}) => {
  return (
    <Card className={twMerge("bg-dark py-2 px-4", card_cn)}>
      {
        <Link
          {...{
            ...(redirect
              ? {
                  to: redirect,
                }
              : {}),
          }}
        >
          <div className="fc-ss resp-gap-1">
            <div className="w-full fr-sc resp-gap-1">
              {icon && (
                <FontAwesomeIcon
                  className={twMerge("resp-text--1", icon_cn)}
                  icon={icon}
                />
              )}
              <span className="text-slate-400 resp-text--3">{title}</span>
            </div>
            <div className="w-full resp-text--1">{children}</div>
          </div>
        </Link>
      }
    </Card>
  );
};

const AssetTransferPopup = ({ i, o, onclose }) => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  console.log("auth", auth, aumode);
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});

  const asty = asset_types_map[i.asset_type];

  const transfer = async (to, token_id, qty) => {
    console.log("transfer contract", to, token_id);
    let assetcon =
      aumode == "thirdweb"
        ? await t3_asset_signer(i.asset_type)
        : await mm_asset_signer(i.asset_type);
    if (!assetcon) return;

    let [method, pars] = ["", []];
    if (asty.erc == 721) {
      method = "transferFrom";
      pars = [o.owner, to, token_id];
    } else if (asty.erc == 1155) {
      method = "safeTransferFrom";
      pars = [o.owner, to, token_id, qty, "0x"];
    }

    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(asty.address, method, pars, "txn", true, {
        active_account,
        use_abimethods_k: asty.erc,
      });
      await cdelay(2000);
    } else {
      tx = await assetcon[method](...pars);
      tx = await tx.wait();
      await cdelay(2000);
    }
    return tx.hash;
  };

  const auto_transfer = async () => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "preparing to transfer you token ...",
        err: null,
      });

      let to = popupdata.to;
      if (to) to = to.toLowerCase();
      if (nils(to) || !validate_eth_address(to))
        throw new Error("invalid transfer address");

      let qty = parseInt(popupdata.qty);
      if (asty.erc == 721) qty = 1;
      if (nils(qty)) throw new Error("invalid quantity");

      let hash = await transfer(to, i.token_id, qty);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      console.log(err);
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };

  const [qotovau] = useQueries([
    q_vaultinfo({ vault: popupdata.to }, { enabled: !nils(popupdata.to) }),
  ]);
  const tovau = useMemo(
    () => getv(qotovau, "data.result", {}),
    [qotovau.dataUpdatedAt],
  );

  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[50rem] max-w-[90vw]")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>

        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>

        <Card className={"w-full bg-dark p-2 fc-ss resp-text--2 resp-gap-1"}>
          <p>
            Transferring <span className="text-acc0">{asty.name}</span> Asset{" "}
          </p>
          <p className="text-acc0">
            <span>Token#{i.token_id}</span>
            <span>{" - "}</span>
            <span>{i.name}</span>
          </p>
          {stage == 1 && (
            <>
              <p className="">
                <span>Recieving Wallet</span>
                <span>{": "}</span>
                <span className="text-acc0">{popupdata.to}</span>
              </p>
              <>
                {nils(tovau?.vault_name) ? (
                  <p className="fr-sc resp-gap-2 text-red-300">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    <span>Unidentified Wallet</span>
                  </p>
                ) : (
                  <p className="">
                    <span>Identified As</span>
                    <span>{": "}</span>
                    <span className="text-acc0">{tovau?.vault_name}</span>
                  </p>
                )}
              </>
              <>
                <p>
                  <span>Quantity</span>
                  <span>{": "}</span>
                  <span className="text-acc0">
                    {asty.erc == 721 ? 1 : popupdata.qty}
                  </span>
                </p>
              </>
            </>
          )}
        </Card>

        {stage == 0 && (
          <>
            {asty.erc == 721 ? (
              <></>
            ) : (
              <div className="fc-ss">
                <p className="my-1 text-acc0 resp-text--1">Quantity</p>
                <div className="w-full max-w-[5rem] resp-text--2 my-1">
                  <InpText
                    {...{
                      id: "inp-qty",
                      placeholder: "Enter qty",
                      inpprops: {
                        className: "w-full bg-dark text-white",
                      },
                      contprops: {
                        className: "w-full bg-dark text-white",
                      },
                      autoComplete: "off",
                      setter: (v) => {
                        console.log("setter", v);
                        if (nils(parseInt(v))) v = null;
                        else v = parseInt(v);
                        set_state_ob(popupdata, set_popupdata, "qty", v);
                      },
                    }}
                  />
                </div>
              </div>
            )}

            <p className="resp-text--3 my-1">Transfer To Address</p>
            <div className="w-full max-w-[25rem] resp-text--2 my-2">
              <InpText
                {...{
                  id: "inp-tovault",
                  placeholder: "Enter Wallet Address",
                  inpprops: {
                    className: "w-full bg-dark text-white",
                  },
                  contprops: {
                    className: "w-full bg-dark text-white",
                  },
                  autoComplete: "off",
                  setter: (v) => {
                    console.log("setter", v);
                    if (nils(v)) v = null;
                    set_state_ob(popupdata, set_popupdata, "to", v);
                  },
                }}
              />
            </div>

            {popupdata.to && (
              <div className="fr-sc resp-gap-2 resp-text--1 my-2 text-acc0">
                {qotovau.isLoading ? (
                  <Loader01c size="s" />
                ) : (
                  <>
                    {nils(tovau?.vault_name) ? (
                      <span className="text-red-300">
                        couldnt identify this wallet
                      </span>
                    ) : (
                      <>
                        <span>Transfer to</span>
                        <span>{tovau?.vault_name}</span>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            <div className=""></div>
            <div className="fr-cc">
              <div className="flex-1"></div>
              <Tag
                onClick={() => set_stage(stage + 1)}
                className={twMerge(
                  "fr-sc text-acc0 resp-gap-2 resp-text--1 border border-acc0",
                )}
              >
                <span>Proceed</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </>
        )}
        {stage == 1 && (
          <>
            <p className="resp-text--2 text-orange-300">
              Please verify details and
            </p>
            <p className="resp-text--2 text-orange-300">
              confirm transaction on Metamask
            </p>

            <div className="fr-cc">
              {popupdata?.loading ? (
                <Tag className="bg-dark text-orange-500 fr-sc">
                  Transferring...
                </Tag>
              ) : (
                <Tag onClick={auto_transfer} className="bg-orange-500 fr-sc">
                  Confirm Transfer
                </Tag>
              )}
            </div>
          </>
        )}
      </Card>
    </PopUp>
  );
};

const AssetMakeBidPopup = ({ i, o, onclose }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});

  const asty = asset_types_map[i.asset_type];

  const make_bid = async (token_id, qty, amt, exp, token) => {
    console.log(o);

    const conaddr = get_dnamarket_conaddr(token);
    const conk = get_dnamarket_k(token);

    if (nils(token) || nils(conaddr)) throw new Error("invalid token");
    // let con = await mm_asset_signer(i.asset_type);
    let amt_wei = toeth(amt);

    const con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            k: conk,
            contractAddress: conaddr,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ k: conk, contractAddress: conaddr });

    let paycon =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);

    let allowance = await paycon.allowance(vault, con.contractAddress);
    allowance = parseFloat(tofeth(allowance));

    let totamt = parseFloat(amt) * qty;
    console.log({ allowance, totamt });
    if (allowance < totamt) {
      let tx = null;
      if (aumode == "thirdweb") {
        tx = await t3_contract_call(
          token,
          "approve",
          [con.contractAddress, toeth(totamt)],
          "txn",
          true,
          { active_account },
        );
      } else {
        tx = await paycon.approve(conaddr, toeth(totamt));
        tx = await tx.wait();
        await cdelay(5000);
      }
      console.log("approve", tx);
    }

    if (!con) return;
    console.log("make_bid contract", { token_id, qty, amt, exp });
    let tx = null;
    let pars = (asty.erc = 1155
      ? ["bid", token_id, i.asset_type, qty, amt_wei, exp, o.owner]
      : ["bid", token_id, i.asset_type, qty, amt_wei, exp, null]);

    if (aumode == "thirdweb") {
      tx = await t3_contract_call(conk, "createMarket", pars, "txn", true, {
        active_account,
      });
      await cdelay(2000);
    } else {
      tx = await con.createMarket(...pars);
      await cdelay(5000);
    }

    return tx?.hash;
  };

  const auto_make_bid = async () => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "preparing to send your bid...",
        err: null,
      });

      console.log("popupdata", popupdata);

      let { exp, qty, amt, token } = popupdata;
      console.log("asty", asty);
      if (asty.erc == 721) qty = 1;

      if (nils(qty)) throw new Error("invalid quantity");

      if (nils(amt)) throw new Error("invalid amount");

      if (!["WETH", "DEZ"].includes(token)) throw new Error("invalid token");

      let [mi, mx] =
        token == "WETH"
          ? [0.0001, 500]
          : token == "DEZ"
            ? [0.001, 1 * 1e13]
            : null;

      if (!_.inRange(amt, mi, mx))
        throw new Error("valid range for amt: Min:0.001 Max:500");

      if (nils(exp)) throw new Error("invalid expiry duration");

      if (exp == "1day") exp = 1 * 24 * 60 * 60;
      else if (exp == "7days") exp = 7 * 24 * 60 * 60;
      else if (exp == "15days") exp = 15 * 24 * 60 * 60;
      else if (exp == "30days") exp = 30 * 24 * 60 * 60;
      else if (exp == "45days") exp = 45 * 24 * 60 * 60;
      else if (exp == "60days") exp = 60 * 24 * 60 * 60;
      else throw new Error("invalid expiry duration");

      let hash = await make_bid(i.token_id, qty, amt, exp, token);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };

  const [qotovau] = useQueries([
    q_vaultinfo({ vault: popupdata.to }, { enabled: !nils(popupdata.to) }),
  ]);
  const tovau = useMemo(
    () => getv(qotovau, "data.result", {}),
    [qotovau.dataUpdatedAt],
  );

  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[50rem] max-w-[90vw]")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>

        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>

        <Card className={"w-full bg-dark p-2 fc-ss resp-text--2 resp-gap-1"}>
          <p>
            Making Bid on <span className="text-acc0">{asty.name}</span> Asset{" "}
          </p>
          <p className="text-acc0">
            <span>Token#{i.token_id}</span>
            <span>{" - "}</span>
            <span>{i.name}</span>
          </p>
          {stage == 1 && (
            <>
              <p className="">
                <span>Qty</span>
                <span>{": "}</span>
                <span className="text-acc0">
                  {asty.erc == 721 ? 1 : popupdata.qty}
                </span>
              </p>
              <p className="">
                <span>Amount</span>
                <span>{": "}</span>
                <span classname="text-acc0">
                  {dec(popupdata.amt, tokdecn(popupdata.token))}{" "}
                  {popupdata.token}
                </span>
              </p>
            </>
          )}
        </Card>

        {stage == 0 && (
          <>
            <p className="my-1 text-acc0 resp-text--1">Token</p>
            <div class="fr-sc">
              {[
                ["WETH", "bg-purple-500/40"],
                ["DEZ", "bg-acc0/40"],
              ].map(([_tok, cn]) => {
                let active = getv(popupdata, "token") == _tok;
                return (
                  <Tag
                    onClick={() =>
                      set_state_ob(popupdata, set_popupdata, "token", _tok)
                    }
                    className={twMerge(
                      cn,
                      active ? "" : "bg-transparent text-white",
                    )}
                  >
                    {_tok}{" "}
                  </Tag>
                );
              })}
            </div>

            {asty.erc == 721 ? (
              <></>
            ) : (
              <div className="fc-ss">
                <p className="my-1 text-acc0 resp-text--1">Quantity</p>
                <div className="w-full max-w-[5rem] resp-text--2 my-1">
                  <InpText
                    {...{
                      id: "inp-qty",
                      placeholder: "Enter qty",
                      inpprops: {
                        className: "w-full bg-dark text-white",
                      },
                      contprops: {
                        className: "w-full bg-dark text-white",
                      },
                      autoComplete: "off",
                      def_val: getv(popupdata, "qty") ?? "",
                      setter: (v) => {
                        console.log("setter", v);
                        if (nils(parseInt(v))) v = null;
                        else v = parseInt(v);
                        set_state_ob(popupdata, set_popupdata, "qty", v);
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {!nils(popupdata?.token) && (
              <>
                <div className="fc-ss">
                  <p className="my-1 text-acc0 resp-text--1">
                    Amount [in {getv(popupdata, "token")}]
                  </p>
                  <div className="w-full max-w-[10rem] resp-text--2 my-1">
                    <InpText
                      {...{
                        id: "inp-amt",
                        placeholder: "Enter amt",
                        inpprops: {
                          className: "w-full bg-dark text-white",
                        },
                        contprops: {
                          className: "w-full bg-dark text-white",
                        },
                        autoComplete: "off",
                        def_val: getv(popupdata, "amt") ?? "",
                        setter: (v) => {
                          console.log("setter", v);

                          if (!nils(v) && v.startsWith(".")) {
                            v = parseFloat(`0${v}`);
                          } else if (nils(parseFloat(v))) v = null;
                          else {
                            v = parseFloat(v);
                          }
                          set_state_ob(popupdata, set_popupdata, "amt", v);
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="fc-ss">
                  <p className="my-1 text-acc0 resp-text--1">Expires In</p>
                  <div className="w-full max-w-[10rem] resp-text--2 my-1">
                    <InpDropdown
                      {...{
                        id: "inp-exp",
                        txt: popupdata.exp ?? "Select Expiry Duration",
                        options: [
                          "1day",
                          "7days",
                          "15days",
                          "30days",
                          "45days",
                          "60days",
                        ],
                        setter: (v) => {
                          console.log("setter", v);
                          if (nils(v)) v = null;
                          set_state_ob(popupdata, set_popupdata, "exp", v);
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className=""></div>
            <div className="fr-cc">
              <div className="flex-1"></div>
              <Tag
                onClick={() => set_stage(stage + 1)}
                className={twMerge(
                  "fr-sc text-acc0 resp-gap-2 resp-text--1 border border-acc0",
                )}
              >
                <span>Proceed</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </>
        )}
        {stage == 1 && (
          <>
            <p className="resp-text--2 text-orange-300">
              Please verify details and
            </p>
            <p className="resp-text--2 text-orange-300">
              confirm transaction on Metamask
            </p>

            <div className="fr-cc">
              {popupdata?.loading ? (
                <Tag className="bg-dark text-orange-500 fr-sc">
                  generating bid...
                </Tag>
              ) : (
                <>
                  <Tag onClick={auto_make_bid} className="bg-orange-500 fr-sc">
                    Create Bid
                  </Tag>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </PopUp>
  );
};

const AssetViewBidsPopup = ({ i, o, onclose }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});
  const is_owner = vault == o.owner;

  const asty = asset_types_map[i.asset_type];

  const execute_bid = async (b) => {
    console.log(b);
    let conaddr = get_dnamarket_conaddr(b.token);
    let conk = get_dnamarket_k(b.token);
    let pars = { k: conk, contractAddress: conaddr };
    let con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            ...pars,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ ...pars });
    let assetcon =
      aumode == "thirdweb"
        ? await t3_asset_signer(i.asset_type)
        : await mm_asset_signer(i.asset_type);
    /*
    let a = await paytoken.allowance(b.tok_reciever, con.contractAddress);
    console.log(
      "allowance met3",
      b.tok_reciever,
      con.contractAddress,
      tofeth(a)
    ); */

    let isapproved = await assetcon.isApprovedForAll(
      b.tok_sender,
      con.contractAddress,
    );

    console.log({ isapproved });

    if (!isapproved) {
      // let approvtx = await assetcon.approve(con.contractAddress, i.token_id);
      let approvtx = null;
      if (aumode == "thirdweb") {
        approvtx = await t3_contract_call(
          asty.address,
          "setApprovalForAll",
          [con.contractAddress, true],
          "txn",
          true,
          { use_abimethods_k: asty.erc, active_account },
        );
        await cdelay(2000);
      } else {
        approvtx = await assetcon.setApprovalForAll(con.contractAddress, true);
        approvtx = await approvtx.wait();
        await cdelay(5000);
      }
      console.log("approvtx", approvtx);
    }

    console.log(b.marketid, b.qty);
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(
        conk,
        "executeMarket",
        [b.marketid, b.qty],
        "txn",
        true,
        { active_account },
      );
      await cdelay(2000);
    } else {
      tx = await con.executeMarket(b.marketid, b.qty);
      await cdelay(2000);
    }
    return tx.hash;
  };

  const auto_execute_bid = async (b) => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "preparing to execute this sale...",
        err: null,
      });

      if (nils(b)) throw new Error("bid not found");

      let hash = await execute_bid(b);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };

  const [qobidslist] = useQueries([
    q_astokinfo_bidslist({
      token_id: i.token_id,
      asset_type: i.asset_type,
      tok_sender: o.owner,
    }),
  ]);
  const [bids, vmap] = useMemo(() => {
    let bids = getv(qobidslist, "data.result.bids", []);
    let vmap = getv(qobidslist, "data.result.vmap", {});
    return [bids, vmap];
  }, [qobidslist.dataUpdatedAt]);

  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[60rem]  max-w-[90vw] overflow-auto")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>

        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>

        {qobidslist.isLoading ? (
          <Loader01c />
        ) : qiserr(qobidslist) ? (
          <p className="text-center text-red-300 resp-text--2">
            {qiserr(qobidslist)}
          </p>
        ) : qissuccesss(qobidslist) && _.isEmpty(bids) ? (
          <p className="text-center text-yellow-300 resp-text--2">
            No Bids currently
          </p>
        ) : qissuccesss(qobidslist) && !_.isEmpty(bids) ? (
          <>
            <table className={tablecn.table}>
              <thead>
                <tr className="thintdrow">
                  <th>MarketID</th>
                  <th>Bidder</th>
                  <th>Amt</th>
                  <th>Qty</th>
                  <th>Expires</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {bids.map((b) => {
                  return (
                    <tr className="thintdrow">
                      <td>...{b.marketid.slice(-5)}</td>
                      <td>
                        <div className="fc-ss">
                          <span className="resp-text--1">
                            {vmap[b.tok_reciever]}
                          </span>
                          <span className="resp-text--3">{b.tok_reciever}</span>
                        </div>
                      </td>
                      <td>
                        <span>{!nils(b.amt) ? b.amt.toString() : "-"}</span>
                        <span>{b.token}</span>
                      </td>
                      <td>
                        <span>{b.qty}</span>
                      </td>

                      <td>
                        <div className="fc-ss">
                          <span className="resp-text--2">
                            {from_time_mini(b.expires_at)}
                          </span>
                          <span className="resp-text--3">
                            {iso_format(b.expires_at)}
                          </span>
                        </div>
                      </td>
                      {is_owner && (
                        <>
                          <td>
                            <Tag
                              className={twMerge("bg-acc0/40 -skew-x-12")}
                              onClick={() => auto_execute_bid(b)}
                            >
                              Accept
                            </Tag>
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </Card>
    </PopUp>
  );
};

const AssetCreateListPopup = ({ i, o, onclose }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});

  const asty = asset_types_map[i.asset_type];

  const make_list = async (token_id, qty, amt, exp, token) => {
    console.log("make_list", { token_id, qty, amt, exp, token });
    let amt_wei = toeth(amt);

    const conaddr = get_dnamarket_conaddr(token);
    const conk = get_dnamarket_k(token);
    console.log(token, conaddr, conk);
    if (nils(token) || nils(conaddr)) throw new Error("invalid token");

    const con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            k: conk,
            contractAddress: conaddr,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ k: conk, contractAddress: conaddr });

    const assetcon =
      aumode == "thirdweb"
        ? await t3_asset_signer(i.asset_type)
        : await mm_asset_signer(i.asset_type);

    let isapproved = await assetcon.isApprovedForAll(
      vault,
      con.contractAddress,
    );

    console.log({ isapproved });

    if (!isapproved) {
      // let approvtx = await assetcon.approve(con.contractAddress, i.token_id);
      let approvtx = null;
      if (aumode == "thirdweb") {
        approvtx = await t3_contract_call(
          asty.address,
          "setApprovalForAll",
          [con.contractAddress, true],
          "txn",
          true,
          { use_abimethods_k: asty.erc, active_account },
        );
        await cdelay(2000);
      } else {
        approvtx = await assetcon.setApprovalForAll(con.contractAddress, true);
        approvtx = await approvtx.wait();
      }
      console.log("approvtx", approvtx);
      await cdelay(5000);
    }

    if (!con) return;
    // console.log("make_list contract", { token_id, qty, amt, exp, token });
    let pars = [
      "list",
      token_id,
      i.asset_type,
      qty,
      amt_wei,
      exp,
      ethers.ZeroAddress,
    ];
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(conk, "createMarket", pars, "txn", true, {
        active_account,
      });
    } else {
      tx = await con.createMarket(...pars);
    }
    return tx.hash;
  };

  const auto_make_list = async () => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "preparing to list your sale list ...",
        err: null,
      });

      console.log("popupdata", popupdata);

      let { exp, qty, amt, token } = popupdata;
      console.log("asty", asty);
      if (asty.erc == 721) qty = 1;

      if (nils(qty)) throw new Error("invalid quantity");

      if (nils(amt)) throw new Error("invalid amount");

      if (!["WETH", "DEZ"].includes(token)) throw new Error("invalid token");

      let [mi, mx] =
        token == "WETH"
          ? [0.0001, 500]
          : token == "DEZ"
            ? [0.001, 1 * 1e13]
            : null;

      if (!_.inRange(amt, mi, mx))
        throw new Error("valid range for amt: Min:0.001 Max:500");

      if (nils(exp)) throw new Error("invalid expiry duration");

      if (exp == "1day") exp = 1 * 24 * 60 * 60;
      else if (exp == "7days") exp = 7 * 24 * 60 * 60;
      else if (exp == "15days") exp = 15 * 24 * 60 * 60;
      else if (exp == "30days") exp = 30 * 24 * 60 * 60;
      else if (exp == "45days") exp = 45 * 24 * 60 * 60;
      else if (exp == "60days") exp = 60 * 24 * 60 * 60;
      else throw new Error("invalid expiry duration");

      let hash = await make_list(i.token_id, qty, amt, exp, token);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      console.log(err);
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };

  const [qotovau] = useQueries([
    q_vaultinfo({ vault: popupdata.to }, { enabled: !nils(popupdata.to) }),
  ]);
  const tovau = useMemo(
    () => getv(qotovau, "data.result", {}),
    [qotovau.dataUpdatedAt],
  );

  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[50rem] max-w-[90vw] overflow-auto")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>

        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>

        <Card className={"w-full bg-dark p-2 fc-ss resp-text--2 resp-gap-1"}>
          <p>
            List <span className="text-acc0">{asty.name}</span> for Sale
          </p>
          <p className="text-acc0">
            <span>Token#{i.token_id}</span>
            <span>{" - "}</span>
            <span>{i.name}</span>
          </p>
          {stage == 1 && (
            <>
              <p className="">
                <span>Qty</span>
                <span>{": "}</span>
                <span className="text-acc0">
                  {asty.erc == 721 ? 1 : popupdata.qty}
                </span>
              </p>
              <p className="">
                <span>Amount</span>
                <span>{": "}</span>
                <span classname="text-acc0">
                  {dec(popupdata.amt, tokdecn(popupdata.token))}{" "}
                  {popupdata.token}
                </span>
              </p>
            </>
          )}
        </Card>

        {stage == 0 && (
          <>
            <p className="my-1 text-acc0 resp-text--1">Token</p>
            <div class="fr-sc">
              {[
                ["WETH", "bg-purple-500/40"],
                ["DEZ", "bg-acc0/40"],
              ].map(([_tok, cn]) => {
                let active = getv(popupdata, "token") == _tok;
                return (
                  <Tag
                    onClick={() =>
                      set_state_ob(popupdata, set_popupdata, "token", _tok)
                    }
                    className={twMerge(
                      cn,
                      active ? "" : "bg-transparent text-white",
                    )}
                  >
                    {_tok}{" "}
                  </Tag>
                );
              })}
            </div>
            {asty.erc == 721 ? (
              <></>
            ) : (
              <div className="fc-ss">
                <p className="my-1 text-acc0 resp-text--1">Quantity</p>
                <div className="w-full max-w-[5rem] resp-text--2 my-1">
                  <InpText
                    {...{
                      id: "inp-qty",
                      placeholder: "Enter qty",
                      inpprops: {
                        className: "w-full bg-dark text-white",
                      },
                      contprops: {
                        className: "w-full bg-dark text-white",
                      },
                      def_val: getv(popupdata, "qty") ?? "",
                      autoComplete: "off",
                      setter: (v) => {
                        console.log("setter", v);
                        if (nils(parseInt(v))) v = null;
                        else v = parseInt(v);
                        set_state_ob(popupdata, set_popupdata, "qty", v);
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {!nils(popupdata?.token) && (
              <>
                <div className="fc-ss">
                  <p className="my-1 text-acc0 resp-text--1">
                    Amount [in {getv(popupdata, "token")}]
                  </p>
                  <div className="w-full max-w-[10rem] resp-text--2 my-1">
                    <InpText
                      {...{
                        id: "inp-amt",
                        placeholder: "Enter amt",
                        inpprops: {
                          className: "w-full bg-dark text-white",
                        },
                        contprops: {
                          className: "w-full bg-dark text-white",
                        },
                        autoComplete: "off",
                        def_val: getv(popupdata, "amt") ?? "",
                        setter: (v) => {
                          console.log("setter", v);

                          if (!nils(v) && v.startsWith(".")) {
                            v = parseFloat(`0${v}`);
                          } else if (nils(parseFloat(v))) v = null;
                          else {
                            v = parseFloat(v);
                          }
                          set_state_ob(popupdata, set_popupdata, "amt", v);
                        },
                      }}
                    />
                  </div>
                  {popupdata.token == "DEZ" &&
                  !nils(popupdata?.amt) &&
                  popupdata.amt < 5000 ? (
                    <p className="text-red-300 resp-text--2">
                      Are you sure? you are selling this asset for less than
                      5000 DEZ
                    </p>
                  ) : null}
                </div>
                <div className="fc-ss">
                  <p className="my-1 text-acc0 resp-text--1">Expires In</p>
                  <div className="w-full max-w-[10rem] resp-text--2 my-1">
                    <InpDropdown
                      {...{
                        id: "inp-exp",
                        txt: popupdata.exp ?? "Select Expiry Duration",
                        options: [
                          "1day",
                          "7days",
                          "15days",
                          "30days",
                          "45days",
                          "60days",
                        ],
                        setter: (v) => {
                          console.log("setter", v);
                          if (nils(v)) v = null;
                          set_state_ob(popupdata, set_popupdata, "exp", v);
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className=""></div>
            <div className="fr-cc">
              <div className="flex-1"></div>
              <Tag
                onClick={() => set_stage(stage + 1)}
                className={twMerge(
                  "fr-sc text-acc0 resp-gap-2 resp-text--1 border border-acc0",
                )}
              >
                <span>Proceed</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </>
        )}
        {stage == 1 && (
          <>
            <p className="resp-text--2 text-orange-300">
              Please verify details and
            </p>
            <p className="resp-text--2 text-orange-300">
              confirm transaction on Metamask
            </p>

            <div className="fr-cc">
              {popupdata?.loading ? (
                <Tag className="bg-dark text-orange-500 fr-sc">Creating...</Tag>
              ) : (
                <>
                  <Tag onClick={auto_make_list} className="bg-orange-500 fr-sc">
                    Create List
                  </Tag>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </PopUp>
  );
};

const AssetBuyListPopup = ({ i, o, onclose, list }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});

  const asty = asset_types_map[i.asset_type];

  const buy_list = async (list, qty) => {
    let { marketid, token } = list;
    const conaddr = get_dnamarket_conaddr(token);
    const conk = get_dnamarket_k(token);
    const con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            k: conk,
            contractAddress: conaddr,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ k: conk, contractAddress: conaddr });

    const assetcon =
      aumode == "thirdweb"
        ? await t3_asset_signer(i.asset_type)
        : await mm_asset_signer(i.asset_type);

    const paycon =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);

    /* let isapproved = await assetcon.isApprovedForAll(
      vault,
      con.contractAddress
    );
    console.log({ isapproved }); */

    let allowance = await paycon.allowance(vault, con.contractAddress);
    allowance = parseFloat(tofeth(allowance));
    let totamt = dec(parseFloat(list.amt) * qty, tokdecn(list.token));

    console.log({ allowance, totamt });
    if (allowance < totamt) {
      let approvtx = null;
      if (aumode == "thirdweb") {
        approvtx = await t3_contract_call(
          token,
          "approve",
          [con.contractAddress, toeth(totamt)],
          "txn",
          true,
          { active_account },
        );
      } else {
        approvtx = await paycon.approve(con.contractAddress, toeth(totamt));
        approvtx = await approvtx.wait();
        await cdelay(2000);
      }
      console.log("approvetx", approvtx);
    }

    if (!con) return;
    console.log("buy_list contract", list.marketid, qty);
    let pars = [list.marketid, qty];
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(conk, "executeMarket", pars, "txn", true, {
        active_account,
      });
      await cdelay(2000);
    } else {
      tx = await con.executeMarket(...pars);
      await cdelay(2000);
    }
    return tx.hash;
  };

  const auto_buy_list = async () => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "preparing to buy this sale list...",
        err: null,
      });

      console.log("popupdata", popupdata);

      let { qty } = popupdata;
      console.log("asty", asty);
      if (asty.erc == 721) qty = 1;
      if (nils(qty)) throw new Error("invalid quantity");

      let hash = await buy_list(list, qty);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };
  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[50rem] max-w-[90vw] overflow-auto")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>
        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>
        <Card className={"w-full bg-dark p-2 fc-ss resp-text--2 resp-gap-1"}>
          <p>
            Buying <span className="text-acc0">{asty.name}</span>
          </p>
          <p className="text-acc0">
            <span>Token#{i.token_id}</span>
            <span>{" - "}</span>
            <span>{i.name}</span>
          </p>
          {stage == 1 && (
            <>
              <p className="">
                <span>Qty</span>
                <span>{": "}</span>
                <span className="text-acc0">
                  {asty.erc == 721 ? 1 : popupdata.qty}
                </span>
              </p>
              <p className="">
                <span>Amount</span>
                <span>{": "}</span>
                <span className="text-acc0">
                  {list.amt.toString()}
                  {list.token}
                </span>
              </p>
            </>
          )}
        </Card>
        {stage == 0 && (
          <>
            {asty.erc == 721 ? (
              <div className="fc-ss">
                <p className="my-1 text-acc0 resp-text--1">Quantity:</p>
                <span className="resp-text-1">1</span>
              </div>
            ) : (
              <div className="fc-ss">
                <p className="my-1 text-acc0 resp-text--1">Quantity</p>
                <div className="w-full max-w-[5rem] resp-text--2 my-1">
                  <InpText
                    {...{
                      id: "inp-qty",
                      placeholder: "Enter qty",
                      inpprops: {
                        className: "w-full bg-dark text-white",
                      },
                      contprops: {
                        className: "w-full bg-dark text-white",
                      },
                      autoComplete: "off",
                      setter: (v) => {
                        console.log("setter", v);
                        if (nils(parseInt(v))) v = null;
                        else v = parseInt(v);
                        set_state_ob(popupdata, set_popupdata, "qty", v);
                      },
                    }}
                  />
                </div>
              </div>
            )}

            <div className=""></div>
            <div className="fr-cc">
              <div className="flex-1"></div>
              <Tag
                onClick={() => set_stage(stage + 1)}
                className={twMerge(
                  "fr-sc text-acc0 resp-gap-2 resp-text--1 border border-acc0",
                )}
              >
                <span>Proceed</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </>
        )}
        {stage == 1 && (
          <>
            <p className="resp-text--1 text-center text-orange-300">
              Please verify details and click buy now
            </p>

            <div className="fr-cc">
              {popupdata?.loading ? (
                <Tag className="bg-dark text-orange-500 fr-sc">Creating...</Tag>
              ) : (
                <>
                  <Tag onClick={auto_buy_list} className="bg-orange-500 fr-sc">
                    Buy Now
                  </Tag>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </PopUp>
  );
};

const AssetDeleteListPopup = ({ i, o, onclose, list }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});

  const asty = asset_types_map[i.asset_type];

  const delete_list = async (list) => {
    const conaddr = get_dnamarket_conaddr(list.token);
    const conk = get_dnamarket_k(list.token);
    const con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            k: conk,
            contractAddress: conaddr,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ k: conk, contractAddress: conaddr });

    if (!con) return;
    console.log("delete_list contract", list.marketid);
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(
        conk,
        "deleteMarket",
        [list.marketid],
        "txn",
        true,
        { active_account },
      );
      await cdelay(2000);
    } else {
      tx = await con.deleteMarket(list.marketid);
      await cdelay(2000);
    }
    return tx.hash;
  };

  const auto_delete_list = async () => {
    try {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: "Preparing to delete list",
        err: null,
      });

      console.log("popupdata", popupdata);
      console.log("asty", asty);

      let hash = await delete_list(list);
      if (!nils(hash)) {
        set_popupdata({
          ...popupdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      set_popupdata({
        ...popupdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popupdata({ ...popupdata, loading: false });
  };

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };

  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[50rem] max-w-[90vw] overflow-auto")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>
        <div className="fr-sc my-2 resp-text--2">
          <div className="w-max">
            {popupdata.loading && <Loader01c size="s" />}
          </div>
          {popupdata.msg && (
            <div className="flex-1 p-2 text-green-300">{popupdata.msg}</div>
          )}
          {popupdata.err && (
            <div className="flex-1 p-2 text-red-300">{popupdata.err}</div>
          )}
        </div>
        <Card className={"w-full bg-dark p-2 fc-ss resp-text--2 resp-gap-1"}>
          <p>
            Delete List on <span className="text-acc0">{asty.name}</span> Asset
          </p>
          <p className="text-acc0">
            <span>Token#{i.token_id}</span>
            <span>{" - "}</span>
            <span>{i.name}</span>
          </p>
          {stage == 0 && (
            <>
              <p className="">
                <span>MarketID</span>
                <span>{": "}</span>
                <span className="text-acc0">{list.marketid}</span>
              </p>
            </>
          )}
        </Card>
        {stage == 0 && (
          <>
            <p className="resp-text--2 text-orange-300">
              Please verify details and
            </p>
            <p className="resp-text--2 text-orange-300">
              confirm transaction on Metamask
            </p>

            <div className="fr-cc">
              {popupdata?.loading ? (
                <Tag className="bg-dark text-orange-500 fr-sc">deleting...</Tag>
              ) : (
                <>
                  <Tag
                    onClick={auto_delete_list}
                    className="bg-orange-500 fr-sc"
                  >
                    Delete List
                  </Tag>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </PopUp>
  );
};

const AssetDeleteMyBidsPopup = ({ i, o, onclose, mybids, vmap }) => {
  const aucon = useAuthContext();
  const { vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const [popupdata, set_popupdata] = useState({});
  const marketids = _.map(mybids, "marketid");

  const asty = asset_types_map[i.asset_type];

  const delete_bid = async (m) => {
    try {
      let { marketid, token } = m;
      const conaddr = get_dnamarket_conaddr(token);
      const conk = get_dnamarket_k(token);
      const con =
        aumode == "thirdweb"
          ? await DNAMarket.get_contract({
              k: conk,
              contractAddress: conaddr,
              nosigner: true,
              rpc: polygon.rpc,
            })
          : await DNAMarket.get_contract({ k: conk, contractAddress: conaddr });

      if (!con || nils(marketid)) return;
      console.log("delete_bid", marketid);
      let tx = null;
      if (aumode == "thirdweb") {
        tx = await t3_contract_call(
          conk,
          "deleteMarket",
          [marketid],
          "txn",
          true,
          { active_account },
        );
        await cdelay(2000);
      } else {
        tx = await con.deleteMarket(marketid);
        await cdelay(2000);
      }
      return tx.hash;
    } catch (err) {
      console.log(err);
    }
  };

  const auto_delete_bids = async () => {};

  const [stage, set_stage] = useState(0);

  const closepopup = async () => {
    set_stage(0);
    set_popupdata({});
    await onclose();
  };
  return (
    <PopUp openstate={true} overlayclose={false}>
      <Card className={twMerge("w-[60rem] max-w-[90vw] overflow-auto")}>
        <div className="fr-sc my-2">
          {stage !== 0 && (
            <Tag
              onClick={() => set_stage(stage - 1)}
              className={twMerge("fr-sc text-acc0 resp-gap-2 resp-text--1")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Tag>
          )}
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>
        <table className={tablecn.table}>
          <thead>
            <tr className="thintdrow">
              <th>MarketID</th>
              <th>Bidder</th>
              <th>Amt</th>
              <th>Qty</th>
              <th>Expires</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {mybids.map((b) => {
              return (
                <tr className="thintdrow resp-text--3">
                  <td>...{b.marketid.slice(-5)}</td>
                  <td>
                    <div className="fc-ss">
                      <span className="resp-text--1">
                        {vmap[b.tok_reciever]}
                      </span>
                      <span className="resp-text--3">{b.tok_reciever}</span>
                    </div>
                  </td>
                  <td>
                    {!nils(b.amt) ? (
                      <>
                        <span>{b.amt.toString()}</span>
                        <span>{b.token}</span>
                      </>
                    ) : null}
                  </td>
                  <td>
                    <span>{b.qty}</span>
                  </td>

                  <td>
                    <div className="fc-ss min-w-[10rem]">
                      <span className="resp-text--2">
                        {from_time_mini(b.expires_at)}
                      </span>
                      <span className="resp-text--3">
                        {iso_format(b.expires_at)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <Tag
                      className={twMerge("bg-red-400 -skew-x-12")}
                      onClick={() => delete_bid(b)}
                    >
                      Delete
                    </Tag>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </PopUp>
  );
};

const ActionButtons = ({ i, o }) => {
  const appcon = useAppContext();
  const { psearch } = appcon;
  const aucon = useAuthContext();
  const { vault, auth } = aucon;

  const asty = asset_types_map[i.asset_type];

  const is_owner = useMemo(() => {
    return vault == o.owner;
  }, [vault, o]);

  const [action, set_action] = useState(null);
  const [actionargs, set_actionargs] = useState({});

  const onclose = () => {
    set_action(null);
  };

  const [qolist, qomybids] = useQueries([
    q_astokinfo_list({
      token_id: i.token_id,
      asset_type: i.asset_type,
      ...(asty.erc == 1155
        ? {
            vault: psearch.a,
          }
        : {}),
    }),
    q_astokinfo_bidslist({
      token_id: i.token_id,
      asset_type: i.asset_type,
      tok_sender: o.owner,
      tok_reciever: vault,
    }),
  ]);
  const list = useMemo(() => {
    return getv(qolist, "data.result", null);
  }, [qolist.dataUpdatedAt]);

  const [mybids, vmap] = useMemo(() => {
    if (qissuccesss(qomybids)) {
      let bids = getv(qomybids, "data.result.bids", []);
      let vmap = getv(qomybids, "data.result.vmap", {});
      return [bids, vmap];
    } else return [[], {}];
  }, [qomybids.dataUpdatedAt]);

  // const asty = asset_types_map[i.asset_type];

  return (
    <>
      {action == "transfer" && <AssetTransferPopup {...{ o, i, onclose }} />}
      {action == "make_bid" && <AssetMakeBidPopup {...{ o, i, onclose }} />}
      {action == "view_bids" && <AssetViewBidsPopup {...{ o, i, onclose }} />}
      {action == "create_list" && (
        <AssetCreateListPopup {...{ o, i, onclose }} />
      )}
      {action == "buy_list" && (
        <AssetBuyListPopup {...{ o, i, onclose, list, ...actionargs }} />
      )}
      {action == "delete_list" && (
        <AssetDeleteListPopup {...{ o, i, onclose, list, ...actionargs }} />
      )}
      {action == "delete_bids" && (
        <AssetDeleteMyBidsPopup {...{ o, i, onclose, mybids, vmap }} />
      )}

      <div className="w-full fc-ss resp-gap-2">
        {qolist.isLoading ? (
          <Loader01c size="s" />
        ) : qissuccesss(qolist) ? (
          <>
            {!_.isEmpty(list) && _.isArray(list) ? (
              <>
                {list?.map((l) => {
                  if (l.valid !== true) return null;

                  return (
                    <div className="border-b border-acc0/40 p-1  resp-text--1">
                      <div class="fr-sc resp-gap-2 ">
                        <div class="fc-ss">
                          <span className="font-digi text-acc0">
                            {l.amt.toString()} {l.token}
                          </span>

                          {asty.erc == 1155 ? (
                            <span>[available: {l.qty} ] </span>
                          ) : null}
                        </div>
                        {is_owner ? (
                          <>
                            {l.locked == true ? (
                              <FontAwesomeIcon
                                icon={faLock}
                                className="text-red-400"
                              />
                            ) : (
                              <Tag
                                onClick={() => {
                                  set_action("delete_list");
                                  set_actionargs({ list: l });
                                }}
                                className={twMerge(
                                  "fr-sc resp-gap-2 bg-red-500",
                                )}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Tag>
                            )}
                          </>
                        ) : (
                          <>
                            <Tag
                              onClick={() => {
                                set_action("buy_list");
                                set_actionargs({ list: l });
                              }}
                              className={twMerge(
                                "fr-sc resp-gap-2",
                                l.token == "WETH"
                                  ? "bg-purple-500/40"
                                  : l.token == "DEZ"
                                    ? "bg-acc0/40"
                                    : "",
                              )}
                            >
                              {
                                <>
                                  <TokenIcon size="xs" token={l.token} />
                                  <span>Buy</span>
                                </>
                              }
                            </Tag>
                          </>
                        )}
                      </div>
                      <span>expires in {from_time_mini(l.expires_at)}</span>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="flex-1"></div>

        <div className="grid grid-cols-2">
          <Tag
            onClick={() => set_action("view_bids")}
            className={twMerge("resp-text--2 fr-sc resp-gap-1 bg-purple-500")}
          >
            <FontAwesomeIcon icon={faHammer} />
            <span>View Offers</span>
          </Tag>

          {is_owner ? (
            <>
              {true && (
                <>
                  <Tag
                    onClick={() => set_action("create_list")}
                    className={twMerge(
                      "resp-text--2 fr-sc resp-gap-1 bg-acc0/40",
                    )}
                  >
                    <FontAwesomeIcon icon={faAnchor} />
                    <span>List for Sale</span>
                  </Tag>
                </>
              )}

              <Tag
                onClick={() => set_action("transfer")}
                className={twMerge(
                  "resp-text--2 fr-sc resp-gap-1 bg-orange-500",
                )}
              >
                <FontAwesomeIcon icon={faUndoAlt} />
                <span>Transfer</span>
              </Tag>
            </>
          ) : (
            <>
              {auth == true ? (
                <>
                  {asty.erc == 721 || (asty.erc == 1155 && o.balance > 0) ? (
                    qissuccesss(qomybids) && (
                      <>
                        {mybids.length <= 0 ? (
                          <Tag
                            onClick={() => set_action("make_bid")}
                            className={twMerge(
                              "resp-text--2 fr-sc resp-gap-1 bg-purple-500",
                            )}
                          >
                            <FontAwesomeIcon icon={faMemory} />
                            <span>Make Bid</span>
                          </Tag>
                        ) : (
                          <Tag
                            onClick={() => set_action("delete_bids")}
                            className={twMerge(
                              "resp-text--2 fr-sc resp-gap-1 bg-red-400",
                            )}
                          >
                            <FontAwesomeIcon icon={faMemory} />
                            <span>Delete {mybids.length} Bid</span>
                          </Tag>
                        )}
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const InfoCardTemplate = ({
  i = {},
  o = {},
  refresh_cache = null,
  InfoImg = <></>,
  ExtraInfo = <></>,
}) => {
  const asty = asset_types_map[i.asset_type];

  const accon = useAccountContext();
  const { vault, watchlist } = accon;

  const [watching, set_watching] = useState(false);
  const isw = useMemo(() => {
    let isw = false;
    if (_.isEmpty(watchlist)) return false;
    for (let w of watchlist) {
      if (
        w.asset_type == i.asset_type &&
        w.token_id == i.token_id &&
        (asty.erc == 1155 ? w.owner == o?.owner : true)
      ) {
        isw = true;
        set_watching(true);
        break;
      }
    }
    return isw;
  }, [jstr(i), jstr(watching), jstr(o)]);

  const add_watchlist = async () => {
    let d = {
      vault: vault,
      token_id: i.token_id,
      asset_type: i.asset_type,
    };
    if (asty.erc == 1155) d.owner = o.owner;
    await q_watchlist_add(d).queryFn();
    setTimeout(accon.qo_watchlist.refetch, 5 * 1e3);
  };
  const del_watchlist = async () => {
    let d = {
      vault: vault,
      token_id: i.token_id,
      asset_type: i.asset_type,
    };
    if (asty.erc == 1155) d.owner = o.owner;
    await q_watchlist_del(d).queryFn();
    setTimeout(accon.qo_watchlist.refetch, 5 * 1e3);
  };

  return (
    <>
      <div className="flex xs:flex-col lg:flex-row justify-start items-stretch resp-gap-2 my-2">
        <div className="max-w-full">{InfoImg}</div>
        <Card className={"bg-reg w-full overflow-auto "}>
          <div className="grid grid-cols-12">
            <div className="xs:col-span-12 lg:col-span-8">
              <div className="fr-sc w-full resp-gap-2 flex-wrap">
                <MiniStatCard title={"TokenID"}>
                  <span>#{i.token_id}</span>
                </MiniStatCard>
                <MiniStatCard title={"Asset Type"}>
                  <span>{asty.name}</span>
                </MiniStatCard>
                {i?.erc == 1155 && (
                  <>
                    <MiniStatCard title={"#Owned"}>
                      <span className="resp-text-1 font-digi text-acc0">
                        <span className="resp-text--1">{"x"}</span>
                        <span>{o?.balance}</span>
                      </span>
                    </MiniStatCard>
                  </>
                )}
                <div className="flex-1"></div>
              </div>

              <div className="w-full fc-ss px-4">
                <div class="fr-sc resp-gap-2">
                  <p className="text-left font-digi text-acc0 resp-text-2 ">
                    {i.name}
                  </p>
                  {i?.asset_type == "core" && i?.is_maiden && (
                    <p class="resp-text--2 text-acc0">ME</p>
                  )}

                  <Tag
                    onClick={() => {
                      set_watching(!watching);
                      if (!watching) add_watchlist();
                      else del_watchlist();
                    }}
                    className={twMerge(
                      watching ? "text-pink-600" : "text-slate-600",
                    )}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                  </Tag>
                </div>
                {o &&
                  (["0x000000000000000000000000000000000000dead"].includes(
                    o.owner,
                  ) ? (
                    <div className="fr-sc">
                      <p className="text-red-300 resp-text--1">
                        {"NFT is RETIRED"}
                      </p>
                    </div>
                  ) : (
                    <div className="fr-sc">
                      <Link to={`/vault/${o.owner}`}>
                        <div className="fc-ss">
                          <span className="resp-text--1">{o.owner_name}</span>
                          <span className="resp-text--3">{o.owner}</span>
                        </div>
                      </Link>
                      {refresh_cache && (
                        <Tag onClick={refresh_cache}>
                          <FontAwesomeIcon icon={faRefresh} />
                        </Tag>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className="xs:col-span-12 lg:col-span-4">
              {!nils(i) && !nils(o) && (
                // o.owner && o.balance > 0
                <ActionButtons {...{ i, o }} />
              )}
            </div>
          </div>
        </Card>
      </div>
      {/* <div className="my-2 h-1 bg-acc0/40 rounded-md" /> */}
      {ExtraInfo}
    </>
  );
};

const CoreInfoCard = ({ i = {}, o = {}, refresh_cache = null }) => {
  const asty = asset_types_map[i.asset_type];

  const gen_bikecard_stats_list = (h) => {
    return [
      ["Type", _.capitalize(h.type), faTeletype],
      [
        "Gender",
        <span className={twMerge(gendermap[h.gender].text)}>
          {_.capitalize(h.gender)}
        </span>,
        gendermap[h.gender].icon,
        gendermap[h.gender].text,
      ],
      ["F.No", `F${h.fno}`, faF],
      [
        "Element",
        <span className={twMerge(elementmap[h.element].text)}>
          {_.capitalize(h.element)}
        </span>,
        elementmap[h.element].icon,
        elementmap[h.element].text,
      ],

      ["Races", getv(h, "career.races_n") ?? 0, faFlag],
      ["Win %", `${dec((getv(h, "career.win_p") ?? 0) * 100, 1)}%`, faTrophy],
      ["Paid Races", getv(h, "career.paid_races_n") ?? 0, faFlag],
      [
        "Paid Win %",
        `${dec((getv(h, "career.paid_win_p") ?? 0) * 100, 1)}%`,
        faTrophy,
      ],

      [
        "Color",
        _.chain(h.color).split("-").map(_.capitalize).join(" ").value(),
        faCircle,
        `text-${h.color}`,
      ],
    ];
  };

  const statcards = useMemo(() => {
    return gen_bikecard_stats_list(i);
  }, [jstr(i)]);

  return (
    <InfoCardTemplate
      {...{
        i,
        o,
        refresh_cache,
        InfoImg: (
          <div className="xs:w-[5rem] lg:w-[20rem] mx-auto rounded-lg overflow-hidden ">
            <video className="w-full h-full" autoPlay loop muted playsInline>
              <source
                src="https://api.dnaracing.run/fbike/static/animations/fbike.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        ),
        ExtraInfo: (
          <Card className={"bg-reg w-full"}>
            <div className="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-1">
              {(statcards || []).map((sc) => {
                if (nils(sc)) return <></>;
                return (
                  <div
                    className={twMerge(
                      sc[0] == `Color` ? "col-span-2" : "col-span-1",
                    )}
                  >
                    <MiniStatCard
                      card_cn={"w-full"}
                      title={sc[0]}
                      icon={sc[2]}
                      icon_cn={sc[3]}
                    >
                      <span>{sc[1]}</span>
                    </MiniStatCard>
                  </div>
                );
              })}
              <MiniStatCard
                card_cn={"w-full bg-acc0/10"}
                title={"Stats"}
                icon={faDna}
                icon_cn={"text-acc0"}
                redirect={`https://fbike.dnaracing.run/bike/${i.token_id}?subtab=summary&tab=races`}
              >
                <span>{"View on Bike Stats"}</span>
              </MiniStatCard>
            </div>
          </Card>
        ),
      }}
    />
  );
};

const SkinInfoCard = ({ i = {}, o = {}, refresh_cache = null, ext = {} }) => {
  const asty = asset_types_map[i.asset_type];

  const gen_stats_list = (s) => {
    return [
      ["SkinName", _.capitalize(s.skin), faCircle],
      ["Rarity", _.capitalize(s.rarity), faCircle],
      ["RVmode", _.capitalize(s.rvmode), faCircle],
    ];
  };

  const statcards = useMemo(() => {
    return gen_stats_list(i);
  }, [jstr(i)]);

  return (
    <InfoCardTemplate
      {...{
        i,
        o,
        refresh_cache,
        InfoImg: (
          <div className="xs:w-[5rem] lg:w-[20rem]  rounded-lg overflow-hidden ">
            <SkinCard skin={i.skin} rarity={i.rarity} rvmode={i.rvmode} />
          </div>
        ),
        ExtraInfo: (
          <Card className={"bg-reg w-full"}>
            <div className="grid grid-cols-4 resp-gap-1">
              {(statcards || []).map((sc) => {
                if (nils(sc)) return <></>;
                return (
                  <div
                    className={twMerge(
                      sc[0] == `Color` ? "col-span-2" : "col-span-1",
                    )}
                  >
                    <MiniStatCard
                      card_cn={"w-full"}
                      title={sc[0]}
                      icon={sc[2]}
                      icon_cn={sc[3]}
                    >
                      <span>{sc[1]}</span>
                    </MiniStatCard>
                  </div>
                );
              })}
            </div>
          </Card>
        ),
      }}
    />
  );
};

const BikeSkinInfoCard = (props) => {
  return <SkinInfoCard {...{ ...props, ext: { rvmode: "bike" } }} />;
};
const CarSkinInfoCard = (props) => {
  return <SkinInfoCard {...{ ...props, ext: { rvmode: "car" } }} />;
};

const GODTokenCard = ({ i = {}, o = {}, refresh_cache = null }) => {
  return (
    <InfoCardTemplate
      {...{
        i,
        o,
        refresh_cache,
        InfoImg: (
          <div className="xs:w-[5rem] lg:w-[20rem]  rounded-lg overflow-hidden ">
            <Img
              img={`https://api.dnaracing.run/fbike/static/images/godtoken.png`}
            />
          </div>
        ),
        ExtraInfo: <></>,
      }}
    />
  );
};

const FactionsAgentInfoCard = ({ i = {}, o = {}, refresh_cache = null }) => {
  const asty = asset_types_map[i.asset_type];

  const gen_bikecard_stats_list = (h) => {
    return [
      [
        "Faction",
        nils(h?.faction)
          ? ""
          : _.chain(h.faction).split("-").map(_.capitalize).join(" ").value(),
        faTeletype,
      ],
      ["Rarity", nils(h?.rarity) ? "" : _.capitalize(h.rarity), faCircle],
    ];
  };

  const statcards = useMemo(() => {
    return gen_bikecard_stats_list(i);
  }, [jstr(i)]);

  return (
    <InfoCardTemplate
      {...{
        i,
        o,
        refresh_cache,
        InfoImg: (
          <div className="xs:w-[5rem] lg:w-[10rem] aspect-[1/2]  rounded-lg overflow-hidden ">
            <Img img={i?.img} />
          </div>
        ),
        ExtraInfo: (
          <Card className={"bg-reg w-full"}>
            <div className="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-1">
              {(statcards || []).map((sc) => {
                if (nils(sc)) return <></>;
                return (
                  <div className={twMerge("")}>
                    <MiniStatCard
                      card_cn={"w-full"}
                      title={sc[0]}
                      icon={sc[2]}
                      icon_cn={sc[3]}
                    >
                      <span>{sc[1]}</span>
                    </MiniStatCard>
                  </div>
                );
              })}
              <MiniStatCard
                card_cn={"w-full bg-acc0/10"}
                title={"Stats"}
                icon={faDna}
                icon_cn={"text-acc0"}
                redirect={`https://fbike.dnaracing.run/factions/${o?.owner}?tab=agents`}
              >
                <span>{"View on Agent and Missions"}</span>
              </MiniStatCard>
            </div>
          </Card>
        ),
      }}
    />
  );
};

const ReportFalseListing = ({ i = {}, o = {} }) => {
  const { auth } = useAuthContext();
  let { asset_type, token_id } = i;
  const asty = asset_types_map[asset_type];
  const [resp, set_resp] = useState({});
  const report = async () => {
    try {
      if (!auth) throw new Error("please login");

      set_resp({ type: "loading", msg: "reporting..." });
      let rp = await q_astokinfo_report_falselist({
        asset_type,
        token_id,
        vault: o.owner,
      }).queryFn();
      if (rp.status == "error") throw new Error(rp.err);
      await cdelay(1000);
      await set_resp({ type: "success", msg: "we will review shortly" });
      setTimeout(() => {
        set_resp({});
      }, 3 * 1e3);
    } catch (err) {
      set_resp({ type: "error", msg: err.message });
      setTimeout(() => {
        set_resp({});
      }, 3 * 1e3);
    }
  };
  return (
    <div class="fr-sc resp-gap-2 resp-text--1">
      <div class="flex-1"></div>
      <Tag
        onClick={() => {
          if (resp?.type == "loading") return;
          report();
        }}
        className={twMerge(
          "fr-sc text-acc0 resp-gap-2 resp-text--1 bg-opacity-10 xs:rounded-full lg:rounded-full",
          _.isEmpty(resp)
            ? "bg-yellow-500"
            : resp.type == "loading"
              ? "bg-blue-500"
              : resp.type == "success"
                ? "bg-green-500"
                : resp.type == "error"
                  ? "bg-red-500"
                  : "",
        )}
      >
        {_.isEmpty(resp) ? (
          <>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-yellow-400 "
            />
            <span className="text-yellow-400">{"Report False Listing"}</span>
          </>
        ) : resp.type == "loading" ? (
          <>
            <FontAwesomeIcon icon={faSpinner} className="text-acc4 spin-anim" />
            <span className="text-acc4">{resp.msg}</span>
          </>
        ) : resp.type == "success" ? (
          <>
            <FontAwesomeIcon
              icon={faCheck}
              className="text-green-400 pulse-anim"
            />
            <span className="text-green-400">{resp.msg}</span>
          </>
        ) : resp.type == "error" ? (
          <>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-red-400 pulse-anim"
            />
            <span className="text-red-400">{resp.msg}</span>
          </>
        ) : (
          <></>
        )}
      </Tag>
    </div>
  );
};

const InfoCard = {
  core: CoreInfoCard,
  skin: BikeSkinInfoCard,
  carskin: CarSkinInfoCard,
  god: GODTokenCard,
  factionsagents: FactionsAgentInfoCard,
  // god: GodInfoCard,
};

export const AssetPage = () => {
  const appcon = useAppContext();
  const { psearch, set_psearch } = appcon;

  const aucon = useAuthContext();
  const { vault } = aucon;

  const p = useParams();
  const [asset_type, token_id] = useMemo(() => {
    return [p.asset_type, parseInt(p.token_id)];
  }, [p]);

  const asty = asset_types_map[asset_type];

  const pagetitle = useMemo(() => {
    return `Asset Page | DNA-Market`;
  }, []);

  const [qoinfo, qoowns, qotxns] = useQueries([
    q_astokinfo({ asset_type, token_id }),
    q_astokinfo_owner({ asset_type, token_id, sentby: psearch.a ?? vault }),
    q_astokinfo_txns({ asset_type, token_id, vault: psearch.a }),
  ]);
  const i = useMemo(() => {
    if (!qissuccesss(qoinfo)) return null;
    let d = getv(qoinfo, "data.result");
    // console.log(d);
    return d;
  }, [qoinfo.dataUpdatedAt]);
  const o = useMemo(() => {
    if (!qissuccesss(qoowns)) return null;
    let d = getv(qoowns, "data.result");
    // console.log(d);
    return d;
  }, [qoowns.dataUpdatedAt]);

  const [txns, tx_vmap] = useMemo(() => {
    if (!qissuccesss(qotxns)) return [[], {}];
    let txns = getv(qotxns, "data.result.txns", []);
    let vmap = getv(qotxns, "data.result.vmap", []);
    // console.log(txns, vmap);
    return [txns, vmap];
  }, [qotxns.dataUpdatedAt]);

  const refresh_cache = async () => {
    await q_astokinfo_owner_cacref({
      asset_type,
      token_id,
      sentby: psearch.a ?? vault,
    }).queryFn();
    await cdelay(1000);
    await qoowns.refetch();
  };

  const ascon = {
    asset_type,
    token_id,
    qotxns,
    txns,
    tx_vmap,
  };

  return (
    <AssetContext.Provider value={ascon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[2rem]"></div>
          {qoinfo.isLoading ? (
            <Loader01c size="s" />
          ) : qiserr(qoinfo) ? (
            <p className="text-center text-red-300 resp-text--2">
              {qiserr(qoinfo)}
            </p>
          ) : qissuccesss(qoinfo) ? (
            <div className="">
              {!nils(InfoCard[asty.asset_type]) ? (
                React.createElement(InfoCard[asty.asset_type], {
                  i,
                  o,
                  refresh_cache,
                })
              ) : (
                <>
                  <InfoCardTemplate
                    {...{
                      i,
                      o,
                      refresh_cache,
                      InfoImg: (
                        <div
                          className={twMerge(
                            "xs:w-[5rem] xs:h-[5rem]",
                            "lg:w-[15rem] lg:h-[15rem]",
                            " rounded-lg overflow-hidden bg-reg",
                          )}
                        >
                          <AssetInfoImg {...{ asset_type, token_id, i }} />
                        </div>
                      ),
                    }}
                  />
                </>
              )}
              <ReportFalseListing {...{ i, o }} />
              <Activity />
              {qotxns.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qotxns) ? (
                <p className="text-center text-red-300 resp-text--2">
                  {qiserr(qotxns)}
                </p>
              ) : qissuccesss(qotxns) ? (
                <TransactionsList {...{ txns, tx_vmap }} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </AssetContext.Provider>
  );
};

const TransactionsList = ({ txns, tx_vmap }) => {
  return (
    <Card className={"w-full overflow-auto max-w-full"}>
      {_.isEmpty(txns) ? (
        <>
          <p className="text-center">No Activity Yet</p>
        </>
      ) : (
        <>
          <table className={tablecn.table}>
            <thead>
              <tr className="thintdrowp4 resp-text--2">
                <td>-</td>
                <td>MarketID</td>
                <td>Type</td>
                <td>From</td>
                <td>To</td>
                <td>Asset</td>
                <td>Amt</td>
                <td>Qty</td>
                <td>Date</td>
              </tr>
            </thead>
            <tbody>
              {_.isArray(txns) &&
                (txns || []).map((t) => {
                  let from = t.from ? t.from.toLowerCase() : null;
                  let to = t.to ? t.to.toLowerCase() : null;

                  return (
                    <tr key={t.id} className="thintdrowp4 resp-text--2">
                      <td>
                        <Link target={"_blank"} to={polytxnlink(t.hash)}>
                          <div className="h-[1.2rem] w-[1.2rem]">
                            <Img img={polychainimg} />
                          </div>
                        </Link>
                      </td>
                      <td>
                        <Tag className="text-acc0">
                          {t.marketid && <span>...{t.marketid.slice(-5)}</span>}
                        </Tag>
                      </td>
                      <td>{t.type}</td>
                      <td>
                        <div className="fc-ss">
                          <span className="resp-text--1">
                            {tx_vmap[from] ?? from}
                          </span>
                          <span className="resp-text--4">{from}</span>
                        </div>
                      </td>
                      <td>
                        <div className="fc-ss">
                          <span className="resp-text--1">
                            {tx_vmap[to] ?? to}
                          </span>
                          <span className="resp-text--3">{to}</span>
                        </div>
                      </td>
                      <td>
                        <Tag className="text-acc0">
                          <span>{t.asset_type}</span>
                          <span>#{t.token_id}</span>
                        </Tag>
                      </td>
                      <td>
                        <Tag
                          className={twMerge(
                            "fr-cc resp-gap-1",
                            t.type.includes("executed") ? "text-acc0" : "",
                          )}
                        >
                          {nils(t.amt) ? (
                            <span className="text-gray-300">--</span>
                          ) : (
                            <>
                              <span>{dec(t.amt, tokdecn2(t.token))}</span>
                              <span>{t.token}</span>
                            </>
                          )}
                        </Tag>
                      </td>
                      <td>
                        <Tag className="fr-cc resp-gap-1">
                          <span>{t.qty}</span>
                        </Tag>
                      </td>
                      <td>
                        <div className="fc-ss xs:min-w-[6rem] lg:min-w-[10rem]">
                          <span className="resp-text--2">
                            {from_time_mini(t.date)}
                          </span>
                          <span className="resp-text--3">
                            {iso_format(t.date)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
    </Card>
  );
};

const Activity = () => {
  let ascon = useContext(AssetContext);
  let { asset_type, token_id } = ascon;

  const [qoactivity] = useQueries([
    q_activity(
      {
        filt: {
          asset_type: [asset_type],
          token_id: [token_id],
        },
      },
      {},
    ),
  ]);

  return (
    <Card className={"w-full overflow-auto max-w-full"}>
      <p className="my-2 font-digi resp-text-1 text-acc0">Activity</p>
      {qoactivity.isLoading ? (
        <Loader01c />
      ) : qiserr(qoactivity) ? (
        <p className="text-center text-red-300 resp-text-1">
          {qiserr(qoactivity)}
        </p>
      ) : qissuccess(qoactivity) ? (
        <ActivityListTable
          hide_asset={true}
          data={getv(qoactivity, "data.result")}
          qo={qoactivity}
        />
      ) : null}
    </Card>
  );
};
